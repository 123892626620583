@import url(https://fonts.googleapis.com/css?family=Roboto:700);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */

  font-family: "Jaldi", sans-serif;
}

.Header {
  width: 100%;
  height: 70px;
  background-color: white;
  border-bottom: 1px solid #cdd6e7;
  color: var(--header-search-color);
  text-align: left;
  font-family: "Major Mono Display", monospace;
  display: flex;
}

.Headerlogo {
  margin-left: 10%;
}

.Footer {
  bottom: 0;
  width: 100%;
  background-color: #202939;
  border-top: 1px solid black;
  padding-bottom: 2.5rem;
  color: white;
  font-family: "Major Mono Display", monospace;
}

:root {
  --header-search-color: rgba(27, 227, 210, 0.689);
  --header1-search-color: rgb(53, 76, 231);
  --search-bar-color: rgba(89, 105, 212, 0.25);
  /* --header-search-color: #ec008c; */
  /* --search-bar-color: rgba(132, 57, 144, 0.483); */
  /* --header-search-color: #5022c4; 
  --search-bar-color: rgba(89, 57, 144, 0.483);*/

  /* --header-search-color: #6dd5fa; */

  /* --header-search-color: #f7971e; */

  --secondary-bg-color: white;
}

.App {
  text-align: center;

  font-family: "Inter", sans-serif;
  font-weight: bold;
}

.Dexnia-logo {
  width: 200px;
  margin-left: 10%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
a {
  text-decoration: none;
}
.Homepage {
  background-color: white;
  background-color: var(--secondary-bg-color);

  /* display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: #202939;
  padding-top: 5%;
}

.App-link {
  color: #61dafb;
}

.Search-div-header {
  margin-left: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  width: 50%;
}

.Bar-header {
  width: 400px;
  height: 30px;
  border-radius: 25px;
  border: 1px solid rgb(53, 76, 231);
  border: 1px solid var(--header1-search-color);
  font-family: "Jaldi", sans-serif;
  font-size: medium;
}

.Bar-header::-webkit-input-placeholder {
  color: rgb(53, 76, 231);
  color: var(--header1-search-color);
}

.Bar-header:-ms-input-placeholder {
  color: rgb(53, 76, 231);
  color: var(--header1-search-color);
}

.Bar-header::placeholder {
  color: rgb(53, 76, 231);
  color: var(--header1-search-color);
}

.Button-header {
  font-family: "Jaldi", sans-serif;
  background: rgb(53, 76, 231);
  background: var(--header1-search-color);
  border: none;
  margin-left: 10px;
  height: 34px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: white;
  border-radius: 25px;
  font-size: medium;
}

.Question {
  font-size: 48px;
  margin-top: 5%;
  margin-bottom: 3%;
  font-family: "Roboto", sans-serif;
  /* font-family: "Inter", sans-serif; */
}

label {
  font-family: "Jaldi", sans-serif;
}

.Search {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.SearchTitle {
  /* background-color: rgba(15, 84, 134, 0.874); */
  /* background: var(--header-search-color);
  background: linear-gradient(
    180deg,
    rgba(80, 34, 196, 0.7833508403361344) 27%,
    var(--secondary-bg-color) 100%
  ); */
  /* background: rgb(236, 0, 140);
  background: linear-gradient(
    180deg,
    rgba(236, 0, 140, 1) 0%,
    rgba(252, 103, 103, 0.8) 46%,
    rgba(255, 255, 255, 1) 100%
  ); */

  /* background: rgb(41, 128, 185);
  background: linear-gradient(
    180deg,
    rgba(41, 128, 185, 1) 0%,
    rgba(109, 213, 250, 0.8309698879551821) 46%,
    rgba(255, 255, 255, 1) 100%
  ); */

  /* background: rgb(247, 151, 30);
  background: linear-gradient(
    180deg,
    rgba(247, 151, 30, 1) 0%,
    rgba(255, 210, 0, 0.861782212885154) 46%,
    rgba(255, 255, 255, 1) 100%
  ); */
  /* background: rgb(17, 153, 142);
  background: linear-gradient(
    180deg,
    rgba(17, 153, 142, 1) 0%,
    rgba(56, 239, 125, 0.8421743697478992) 46%,
    rgba(255, 255, 255, 1) 100%
  ); */
  background: rgb(27, 227, 211);
  background: linear-gradient(
    90deg,
    rgba(27, 227, 211, 1) 0%,
    rgba(108, 124, 212, 1) 50%,
    rgba(97, 8, 177, 1) 100%
  );
  width: 100%;
  height: 100%;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Searchbar {
  display: inline-block;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: rgba(89, 105, 212, 0.25);
  background-color: var(--search-bar-color);
  width: 700px;
  height: 90px;
  border-radius: 35px;
}

.Bar {
  width: 500px;
  height: 30px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: rgb(248, 245, 245);
  background-color: rgba(10, 66, 177, 0);
  color: white;
  font-family: "Roboto", sans-serif;
  /* font-family: "Jaldi", sans-serif; */
  font-size: large;
  font-weight: 100;
}
.Bar::-webkit-input-placeholder {
  color: white;
}
.Bar:-ms-input-placeholder {
  color: white;
}
.Bar::placeholder {
  color: white;
}

.ButtonSearch {
  font-family: "Jaldi", sans-serif;
  background: rgba(27, 227, 210, 0.689);
  background: var(--header-search-color);
  border: none;
  margin-left: 10px;
  height: 34px;
  width: 100px;
  color: white;
  border-radius: 25px;
  font-size: larger;
}

.ButtonSearch:hover {
  background-color: rgb(53, 76, 231);
  background-color: var(--header1-search-color);
}

.TrendingList {
  margin: auto;
  margin-top: 3%;
  width: 50%;
}

.ButtonTopic {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  font-weight: 100;
  color: white;
  background-color: rgba(27, 227, 210, 0.689);
  background-color: var(--header-search-color);
  border: none;
  border-radius: 25px;
  margin-right: 4px;
  margin-top: 4px;
}

.ButtonTopic:hover {
  background-color: rgb(53, 76, 231);
  background-color: var(--header1-search-color);
}

.Recommendations {
  font-family: "Inter", sans-serif;
  text-align: left;
}

.Mission {
  margin-top: 50px;
  background: rgb(239, 243, 247);
  text-align: center;
  height: 340px;
}

.Mission-Title {
  margin-top: 100px;
  font-size: 70px;
  font-family: "Major Mono Display", monospace;
  color: rgba(27, 227, 210, 0.689);
  color: var(--header-search-color);
  padding-top: 4%;
}

.Mission-body {
  margin: 0 auto;
  font-size: medium;
  font-weight: 100;
  width: 70%;
}

.Div1 {
  display: flex;
  margin-top: 70px;
  padding-bottom: 10px;
}

.Div2 {
  display: flex;
}

.Div3 {
  height: 50px;
  display: flex;
}

.Circle1 {
  /* background: rgb(66, 239, 190); */
  background: rgb(255, 193, 116);
  background: radial-gradient(
    circle,
    rgba(255, 193, 116, 1) 23%,
    rgba(237, 143, 3, 1) 100%
  );
  width: 300px;
  height: 300px;
  border-radius: 50%;
  font-family: "Jaldi", sans-serif;
  margin-left: 15%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.Circle1 h3 {
  color: #202939;
  margin-top: 33%;
  font-weight: 100;
}

.Circle2 {
  background: rgb(231, 255, 188);
  background: radial-gradient(
    circle,
    rgba(231, 255, 188, 1) 0%,
    rgba(142, 245, 151, 1) 84%
  );
  height: 270px;
  width: 270px;
  border-radius: 50%;
  font-family: "Jaldi", sans-serif;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin-left: 60%;
  top: -210px;
  position: relative;
}

.Circle2 h3 {
  color: #202939;
  margin-top: 32%;
  font-weight: 100;
}

.Circle3 {
  background: rgb(185, 221, 255);
  background: radial-gradient(
    circle,
    rgba(185, 221, 255, 1) 0%,
    rgba(97, 144, 240, 1) 84%
  );
  width: 260px;
  height: 260px;
  border-radius: 50%;
  font-family: "Jaldi", sans-serif;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin-left: 37%;
  top: -280px;
  position: relative;
}

.Circle3 h3 {
  color: #202939;
  margin-top: 30%;
  font-weight: 100;
}

.Listrecom {
  background: rgb(239, 243, 247);
  text-align: center;
  height: 340px;
}

#container {
  padding-top: 7%;
  font-family: "Roboto";
  text-align: center;
  color: rgba(27, 227, 210, 0.689);
  color: var(--header-search-color);
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
}

#flip {
  height: 50px;
  overflow: hidden;
}

#flip > div > div {
  color: #fff;
  padding: 4px 12px;
  height: 45px;
  margin-bottom: 45px;
  display: inline-block;
}

#flip div:first-child {
  animation: show 5s linear infinite;
}

#flip div div {
  background: #42c58a;
}
#flip div:first-child div {
  background: #4ec7f3;
}
#flip div:last-child div {
  background: #e87e49;
}

@keyframes show {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}

.Soon {
  height: 300px;
}

.Searchdiv {
  background: rgb(27, 227, 211);
  background: linear-gradient(
    90deg,
    rgba(27, 227, 211, 1) 0%,
    rgba(108, 124, 212, 1) 50%,
    rgba(97, 8, 177, 1) 100%
  );
  height: 360px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.Question1 h1 {
  font-size: 40px;
  color: rgb(254, 254, 254);
}

.Question1 h2 {
  color: rgb(16, 25, 186);
}

.Presentation {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
}

.Who {
  width: 50%;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
}

.Who h2 {
  font-family: "Roboto", sans-serif;
  color: rgb(131, 61, 196);
  font-size: xx-large;
  margin-bottom: 0px;
}

.Who p {
  color: rgb(154, 155, 157);
  font-weight: 100;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
}

.People {
  /* margin-top: 12%;
  margin-left: 20%;
  width: 430px; */
  margin-top: 7%;
  margin-bottom: 7%;
  width: 60%;
  height: auto;
  margin-right: 10%;
}

:root {
  /* --main-bg-color: #faf9ff; */
  --main-border-color: white;
  --main-bg-color: linear-gradient(
    90deg,
    rgb(239, 255, 254) 0%,
    rgba(241, 243, 255, 1) 50%,
    rgba(249, 242, 255, 1) 100%
  );
}

.Results {
  background: linear-gradient(
    90deg,
    rgb(239, 255, 254) 0%,
    rgba(241, 243, 255, 1) 50%,
    rgba(249, 242, 255, 1) 100%
  );
  background: var(--main-bg-color);
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 680px;
}

.Divtop {
  background: linear-gradient(
    90deg,
    rgb(239, 255, 254) 0%,
    rgba(241, 243, 255, 1) 50%,
    rgba(249, 242, 255, 1) 100%
  );
  background: var(--main-bg-color);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Div-Result-Num {
  background: white;
  width: 100%;
  border-top: 1px solid white;
  border-top: 1px solid var(--main-border-color);
  border-bottom: 1px solid white;
  border-bottom: 1px solid var(--main-border-color);
  color: var(--header1-search-color);
  box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.2),
    0 -8px 8px -8px rgba(0, 0, 0, 0.2);
}

.Structure {
  display: flex;
}

.Course-Selected {
  margin-left: 5%;
  background: white;
  width: 50%;
  height: 500px;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid white;
  border: 1px solid var(--main-border-color);
}

.Result-list {
  /* background: var(--main-bg-color); */
  height: 500px;
  overflow: scroll;
  width: 400px;
  margin-left: 3%;
}

.Course-Card {
  background-color: white;
  width: 99%;
  /* border: 1px solid rgb(214, 233, 247); */
  border: 1px solid white;
  border: 1px solid var(--main-border-color);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  font-weight: 400;
  margin: 0 auto;

  margin-top: 3px;
  border-radius: 5px;
}

.Image-Card {
  float: right;
  border: 1px solid white;
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  width: 30px;
  margin-top: 10px;
  margin-right: 5px;
}

.Course-Title {
  display: block;
  text-align: left;
  color: rgb(25, 40, 67);
  font-size: 16px;
  font-weight: 900;
  text-transform: capitalize;
  margin-left: 10px;
  margin-right: 10px;
}

.Course-Body {
  color: rgb(99, 103, 108);
  margin-left: 10px;
  margin-right: 10px;
  text-align: justify;
  font-size: 12px;
  display: block;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Course-Card:hover {
  background: rgb(202, 216, 247);
}

.loader {
  border: 10px solid rgb(27, 227, 211);
  border-radius: 50%;
  border-top: 10px solid rgb(90, 2, 166);
  width: 90px;
  height: 90px; /* Safari */
  animation: spin 2s linear infinite;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.DivSelected {
  width: 100%;
}

.Course-Selected {
  margin-left: 15%;
  width: 480px;
  text-align: left;
  position: relative;
}

.Course-Selected-Top {
  border-bottom: 1px solid var(--main-border-color);
  height: 90px;
}

.Course-Selected-Middle {
  height: 330px;
}

.Course-Selected-Bottom {
  border-top: 1px solid var(--main-border-color);
  height: 40px;
}

.Course-Image {
  border-radius: 5px 5px 0 0;
}

.Course-Selected-Title {
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: x-large;
  margin-left: 15px;
  margin-right: 15px;
  color: rgb(25, 40, 67);
}

.Course-Selected-Body {
  color: rgb(99, 103, 108);
  margin-left: 30px;
  margin-right: 40px;
  text-align: justify;
  font-weight: normal;
  font-size: 13px;
}

.Course-Selected-Price {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  color: rgb(25, 40, 67);
  text-align: right;
  margin-right: 30px;
}

.Company-Logo {
  width: 100px;
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
}

.Course-Link {
  font-family: "Roboto", sans-serif;
  font-size: large;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  margin-bottom: 10px;
  color: var(--header1-search-color);
}

.Selected-Image {
  float: right;
  width: 30px;
  margin-top: 20px;
  margin-right: 20px;
}

.Share {
  color: var(--header-search-color);
  text-align: right;
  margin-right: 30px;
}

