body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */

  font-family: "Jaldi", sans-serif;
}

.Header {
  width: 100%;
  height: 70px;
  background-color: white;
  border-bottom: 1px solid #cdd6e7;
  color: var(--header-search-color);
  text-align: left;
  font-family: "Major Mono Display", monospace;
  display: flex;
}

.Headerlogo {
  margin-left: 10%;
}

.Footer {
  bottom: 0;
  width: 100%;
  background-color: #202939;
  border-top: 1px solid black;
  padding-bottom: 2.5rem;
  color: white;
  font-family: "Major Mono Display", monospace;
}
