.DivSelected {
  width: 100%;
}

.Course-Selected {
  margin-left: 15%;
  width: 480px;
  text-align: left;
  position: relative;
}

.Course-Selected-Top {
  border-bottom: 1px solid var(--main-border-color);
  height: 90px;
}

.Course-Selected-Middle {
  height: 330px;
}

.Course-Selected-Bottom {
  border-top: 1px solid var(--main-border-color);
  height: 40px;
}

.Course-Image {
  border-radius: 5px 5px 0 0;
}

.Course-Selected-Title {
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: x-large;
  margin-left: 15px;
  margin-right: 15px;
  color: rgb(25, 40, 67);
}

.Course-Selected-Body {
  color: rgb(99, 103, 108);
  margin-left: 30px;
  margin-right: 40px;
  text-align: justify;
  font-weight: normal;
  font-size: 13px;
}

.Course-Selected-Price {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  color: rgb(25, 40, 67);
  text-align: right;
  margin-right: 30px;
}

.Company-Logo {
  width: 100px;
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
}

.Course-Link {
  font-family: "Roboto", sans-serif;
  font-size: large;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  margin-bottom: 10px;
  color: var(--header1-search-color);
}

.Selected-Image {
  float: right;
  width: 30px;
  margin-top: 20px;
  margin-right: 20px;
}

.Share {
  color: var(--header-search-color);
  text-align: right;
  margin-right: 30px;
}
