:root {
  /* --main-bg-color: #faf9ff; */
  --main-border-color: white;
  --main-bg-color: linear-gradient(
    90deg,
    rgb(239, 255, 254) 0%,
    rgba(241, 243, 255, 1) 50%,
    rgba(249, 242, 255, 1) 100%
  );
}

.Results {
  background: var(--main-bg-color);
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 680px;
}

.Divtop {
  background: var(--main-bg-color);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Div-Result-Num {
  background: white;
  width: 100%;
  border-top: 1px solid var(--main-border-color);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--header1-search-color);
  box-shadow: 0 8px 8px -8px rgba(0, 0, 0, 0.2),
    0 -8px 8px -8px rgba(0, 0, 0, 0.2);
}

.Structure {
  display: flex;
}

.Course-Selected {
  margin-left: 5%;
  background: white;
  width: 50%;
  height: 500px;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--main-border-color);
}

.Result-list {
  /* background: var(--main-bg-color); */
  height: 500px;
  overflow: scroll;
  width: 400px;
  margin-left: 3%;
}

.Course-Card {
  background-color: white;
  width: 99%;
  /* border: 1px solid rgb(214, 233, 247); */
  border: 1px solid var(--main-border-color);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  font-weight: 400;
  margin: 0 auto;

  margin-top: 3px;
  border-radius: 5px;
}

.Image-Card {
  float: right;
  border: 1px solid var(--main-border-color);
  border-radius: 5px;
  width: 30px;
  margin-top: 10px;
  margin-right: 5px;
}

.Course-Title {
  display: block;
  text-align: left;
  color: rgb(25, 40, 67);
  font-size: 16px;
  font-weight: 900;
  text-transform: capitalize;
  margin-left: 10px;
  margin-right: 10px;
}

.Course-Body {
  color: rgb(99, 103, 108);
  margin-left: 10px;
  margin-right: 10px;
  text-align: justify;
  font-size: 12px;
  display: block;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Course-Card:hover {
  background: rgb(202, 216, 247);
}

.loader {
  border: 10px solid rgb(27, 227, 211);
  border-radius: 50%;
  border-top: 10px solid rgb(90, 2, 166);
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
