.Searchdiv {
  background: rgb(27, 227, 211);
  background: linear-gradient(
    90deg,
    rgba(27, 227, 211, 1) 0%,
    rgba(108, 124, 212, 1) 50%,
    rgba(97, 8, 177, 1) 100%
  );
  height: 360px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.Question1 h1 {
  font-size: 40px;
  color: rgb(254, 254, 254);
}

.Question1 h2 {
  color: rgb(16, 25, 186);
}

.Presentation {
  height: max-content;
  display: flex;
}

.Who {
  width: 50%;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
}

.Who h2 {
  font-family: "Roboto", sans-serif;
  color: rgb(131, 61, 196);
  font-size: xx-large;
  margin-bottom: 0px;
}

.Who p {
  color: rgb(154, 155, 157);
  font-weight: 100;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
}

.People {
  /* margin-top: 12%;
  margin-left: 20%;
  width: 430px; */
  margin-top: 7%;
  margin-bottom: 7%;
  width: 60%;
  height: auto;
  margin-right: 10%;
}
