:root {
  --header-search-color: rgba(27, 227, 210, 0.689);
  --header1-search-color: rgb(53, 76, 231);
  --search-bar-color: rgba(89, 105, 212, 0.25);
  /* --header-search-color: #ec008c; */
  /* --search-bar-color: rgba(132, 57, 144, 0.483); */
  /* --header-search-color: #5022c4; 
  --search-bar-color: rgba(89, 57, 144, 0.483);*/

  /* --header-search-color: #6dd5fa; */

  /* --header-search-color: #f7971e; */

  --secondary-bg-color: white;
}

.App {
  text-align: center;

  font-family: "Inter", sans-serif;
  font-weight: bold;
}

.Dexnia-logo {
  width: 200px;
  margin-left: 10%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
a {
  text-decoration: none;
}
.Homepage {
  background-color: var(--secondary-bg-color);

  /* display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: #202939;
  padding-top: 5%;
}

.App-link {
  color: #61dafb;
}

.Search-div-header {
  margin-left: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  width: 50%;
}

.Bar-header {
  width: 400px;
  height: 30px;
  border-radius: 25px;
  border: 1px solid var(--header1-search-color);
  font-family: "Jaldi", sans-serif;
  font-size: medium;
}

.Bar-header::placeholder {
  color: var(--header1-search-color);
}

.Button-header {
  font-family: "Jaldi", sans-serif;
  background: var(--header1-search-color);
  border: none;
  margin-left: 10px;
  height: 34px;
  width: max-content;
  color: white;
  border-radius: 25px;
  font-size: medium;
}

.Question {
  font-size: 48px;
  margin-top: 5%;
  margin-bottom: 3%;
  font-family: "Roboto", sans-serif;
  /* font-family: "Inter", sans-serif; */
}

label {
  font-family: "Jaldi", sans-serif;
}

.Search {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.SearchTitle {
  /* background-color: rgba(15, 84, 134, 0.874); */
  /* background: var(--header-search-color);
  background: linear-gradient(
    180deg,
    rgba(80, 34, 196, 0.7833508403361344) 27%,
    var(--secondary-bg-color) 100%
  ); */
  /* background: rgb(236, 0, 140);
  background: linear-gradient(
    180deg,
    rgba(236, 0, 140, 1) 0%,
    rgba(252, 103, 103, 0.8) 46%,
    rgba(255, 255, 255, 1) 100%
  ); */

  /* background: rgb(41, 128, 185);
  background: linear-gradient(
    180deg,
    rgba(41, 128, 185, 1) 0%,
    rgba(109, 213, 250, 0.8309698879551821) 46%,
    rgba(255, 255, 255, 1) 100%
  ); */

  /* background: rgb(247, 151, 30);
  background: linear-gradient(
    180deg,
    rgba(247, 151, 30, 1) 0%,
    rgba(255, 210, 0, 0.861782212885154) 46%,
    rgba(255, 255, 255, 1) 100%
  ); */
  /* background: rgb(17, 153, 142);
  background: linear-gradient(
    180deg,
    rgba(17, 153, 142, 1) 0%,
    rgba(56, 239, 125, 0.8421743697478992) 46%,
    rgba(255, 255, 255, 1) 100%
  ); */
  background: rgb(27, 227, 211);
  background: linear-gradient(
    90deg,
    rgba(27, 227, 211, 1) 0%,
    rgba(108, 124, 212, 1) 50%,
    rgba(97, 8, 177, 1) 100%
  );
  width: 100%;
  height: 100%;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Searchbar {
  display: inline-block;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: var(--search-bar-color);
  width: 700px;
  height: 90px;
  border-radius: 35px;
}

.Bar {
  width: 500px;
  height: 30px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: rgb(248, 245, 245);
  background-color: rgba(10, 66, 177, 0);
  color: white;
  font-family: "Roboto", sans-serif;
  /* font-family: "Jaldi", sans-serif; */
  font-size: large;
  font-weight: 100;
}
.Bar::placeholder {
  color: white;
}

.ButtonSearch {
  font-family: "Jaldi", sans-serif;
  background: var(--header-search-color);
  border: none;
  margin-left: 10px;
  height: 34px;
  width: 100px;
  color: white;
  border-radius: 25px;
  font-size: larger;
}

.ButtonSearch:hover {
  background-color: var(--header1-search-color);
}

.TrendingList {
  margin: auto;
  margin-top: 3%;
  width: 50%;
}

.ButtonTopic {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  font-weight: 100;
  color: white;
  background-color: var(--header-search-color);
  border: none;
  border-radius: 25px;
  margin-right: 4px;
  margin-top: 4px;
}

.ButtonTopic:hover {
  background-color: var(--header1-search-color);
}

.Recommendations {
  font-family: "Inter", sans-serif;
  text-align: left;
}

.Mission {
  margin-top: 50px;
  background: rgb(239, 243, 247);
  text-align: center;
  height: 340px;
}

.Mission-Title {
  margin-top: 100px;
  font-size: 70px;
  font-family: "Major Mono Display", monospace;
  color: var(--header-search-color);
  padding-top: 4%;
}

.Mission-body {
  margin: 0 auto;
  font-size: medium;
  font-weight: 100;
  width: 70%;
}

.Div1 {
  display: flex;
  margin-top: 70px;
  padding-bottom: 10px;
}

.Div2 {
  display: flex;
}

.Div3 {
  height: 50px;
  display: flex;
}

.Circle1 {
  /* background: rgb(66, 239, 190); */
  background: rgb(255, 193, 116);
  background: radial-gradient(
    circle,
    rgba(255, 193, 116, 1) 23%,
    rgba(237, 143, 3, 1) 100%
  );
  width: 300px;
  height: 300px;
  border-radius: 50%;
  font-family: "Jaldi", sans-serif;
  margin-left: 15%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.Circle1 h3 {
  color: #202939;
  margin-top: 33%;
  font-weight: 100;
}

.Circle2 {
  background: rgb(231, 255, 188);
  background: radial-gradient(
    circle,
    rgba(231, 255, 188, 1) 0%,
    rgba(142, 245, 151, 1) 84%
  );
  height: 270px;
  width: 270px;
  border-radius: 50%;
  font-family: "Jaldi", sans-serif;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin-left: 60%;
  top: -210px;
  position: relative;
}

.Circle2 h3 {
  color: #202939;
  margin-top: 32%;
  font-weight: 100;
}

.Circle3 {
  background: rgb(185, 221, 255);
  background: radial-gradient(
    circle,
    rgba(185, 221, 255, 1) 0%,
    rgba(97, 144, 240, 1) 84%
  );
  width: 260px;
  height: 260px;
  border-radius: 50%;
  font-family: "Jaldi", sans-serif;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin-left: 37%;
  top: -280px;
  position: relative;
}

.Circle3 h3 {
  color: #202939;
  margin-top: 30%;
  font-weight: 100;
}

.Listrecom {
  background: rgb(239, 243, 247);
  text-align: center;
  height: 340px;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:700");

#container {
  padding-top: 7%;
  font-family: "Roboto";
  text-align: center;
  color: var(--header-search-color);
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
}

#flip {
  height: 50px;
  overflow: hidden;
}

#flip > div > div {
  color: #fff;
  padding: 4px 12px;
  height: 45px;
  margin-bottom: 45px;
  display: inline-block;
}

#flip div:first-child {
  animation: show 5s linear infinite;
}

#flip div div {
  background: #42c58a;
}
#flip div:first-child div {
  background: #4ec7f3;
}
#flip div:last-child div {
  background: #e87e49;
}

@keyframes show {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}

.Soon {
  height: 300px;
}
